import { format, isToday, isThisYear, isAfter, parse, isEqual } from 'date-fns'

const fmt = (val, pattern) => {
  try {
    if (val) {
      return format(new Date(val), pattern)
    }
  } catch (error) {
    return null
  }
}

export const formatDateMinimal = val => {
  const now = new Date(val)

  return fmt(
    val,
    isToday(now)
      ? 'h:mm aa'
      : isThisYear(now)
        ? 'd MMM, h:mm aa'
        : 'd MMM yyyy, h:mm aa'
  )
}

export const checkIsOnOrAfter = (dateToCheck, compareDate) => {
  return (
    isAfter(dateToCheck, compareDate) ||
    dateToCheck.getTime() === compareDate.getTime()
  )
}

export const isPastDate = date => {
  if (!date) {
    return true
  }

  const d = parse(date, 'yyyy-MM-dd', new Date())
  const today = new Date().setHours(0, 0, 0, 0) // Set to midnight
  return isEqual(d, today) || isAfter(d, today)
}
