import API from './apiHelper'
import { formatAddress } from '@/utils/swoop'
import { sortBy } from 'lodash'

export default {
  get(partyUid) {
    return API.get(`/party/${partyUid}`, {
      transform(response) {
        if (response.data) {
          // Adds formattedAddress value for use in templates
          response.data.formattedAddress = formatAddress(response.data.address)
        }
        return response.data
      }
    })
  },

  totals(partyUid) {
    // Gets total active services, total amount due, total active tickets etc.
    return API.get(`/party/${partyUid}/summary`, {
      transform(response) {
        response.data.totalAmountDue =
          '$' + Number(response.data.totalAmountDue).toFixed(2)
        return response.data
      }
    })
  },

  getUsers(partyUid) {
    // Gets a JSON array of cognito users belonging to a specified party.
    return API.get(`/party/${partyUid}/user`, {
      transform(response) {
        return response.data || []
      }
    })
  },

  search(search = '') {
    return API.get(`/party?search=${search}&pageLength=50`, {
      transform(response) {
        return response.data
      }
    })
  },

  getContacts(partyUid) {
    return API.get(`/contact?partyUid=${partyUid}&pageLength=100000`, {
      transform(response) {
        // Return contacts array sorted by name, then email...
        return (
          sortBy(response.data, [
            function (c) {
              return `${c.firstName} ${c.lastName}`.toLowerCase()
            },
            function (c) {
              return c.email.toLowerCase()
            }
          ]) || []
        )
      }
    })
  },

  getSalesforceAccountId(partyUid) {
    return API.get(`/party/${partyUid}/salesforceAccountId`, {
      transform(response) {
        return response.data
      }
    })
  }
}
