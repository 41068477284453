import { defineStore } from 'pinia'

export const useOrderStore = defineStore('order', {
  state: () => ({
    order: null,
    provisioningData: null
  }),
  getters: {
    currentProvisioningStatus: state => {
      const activities =
        state.provisioningData?.process_instance?.childActivityInstances

      if (!activities?.length) {
        return null
      }

      return activities[0]?.activityName
    }
  },
  actions: {
    setOrder(data) {
      this.order = data
    },
    setProvisioningData(data) {
      this.provisioningData = data
    }
  }
})
